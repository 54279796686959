export default function AnalyticsDashboard() {
  return (
    <div style={{ padding: '1rem', height: 'calc(100vh - 70px)', width: '100%'}}>
      <iframe
        title="Unnoti Analytics Dashboard"
        width="100%"
        height="100%"
        src='https://app.powerbi.com/reportEmbed?reportId=3654a8a1-9b8f-4b1a-8f0f-c277126ad778&autoAuth=true&ctid=ff9c7474-421d-4957-8d47-c4b64dec87b5'
        frameborder="0"
        allowFullScreen="true"
      />
    </div>
  )
}
